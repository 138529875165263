/* ms - grid */

/* grid */
.grid-wrapper-min-max {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  -ms-grid-rows: auto;
}

/* grid-wrapper-min-max - default - ms support */

.grid-wrapper-min-max .grid-item:nth-child(1) {
-ms-grid-column: 1;
-ms-grid-column-span: 3;
}
.grid-wrapper-min-max .grid-item:nth-child(2) {
-ms-grid-column: 4;
-ms-grid-column-span: 3;
}
.grid-wrapper-min-max .grid-item:nth-child(3) {
-ms-grid-column: 7;
-ms-grid-column-span: 3;
}
.grid-wrapper-min-max .grid-item:nth-child(4) {
-ms-grid-column: 10;
-ms-grid-column-span: 3;
}

/* one-column-build - ms support */

.grid-wrapper-min-max.one-column .grid-item {
  -ms-grid-column-span: 12;
  -ms-grid-row-span: 0;
}
.grid-wrapper-min-max.one-column .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
}
.grid-wrapper-min-max.one-column .grid-item:nth-child(2),
.grid-wrapper-min-max.one-column .grid-item:nth-child(3),
.grid-wrapper-min-max.one-column .grid-item:nth-child(4) {
  display: none;
}

/* two-column-build */

.grid-wrapper-min-max.two-column .grid-item {
  -ms-grid-column-span: 6;
}
/* two-column-build - ms support */
.grid-wrapper-min-max.two-column .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 6;
}
.grid-wrapper-min-max.two-column .grid-item:nth-child(2) {
  -ms-grid-column: 7;
  -ms-grid-column-span: 6;
}
.grid-wrapper-min-max.two-column .grid-item:nth-child(3),
.grid-wrapper-min-max.two-column .grid-item:nth-child(4) {
  display: none;
}

/* three-column-build - ms support */

.grid-wrapper-min-max.three-column .grid-item {
  -ms-grid-column-span: 4;
}
/* three-column-build - ms support */
.grid-wrapper-min-max.three-column .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
}
.grid-wrapper-min-max.three-column .grid-item:nth-child(2) {
  -ms-grid-column: 5;
  -ms-grid-column-span: 4;
}
.grid-wrapper-min-max.three-column .grid-item:nth-child(3) {
  -ms-grid-column: 9;
  -ms-grid-column-span: 4;
}
.grid-wrapper-min-max.three-column .grid-item:nth-child(4) {
  display: none;
}

/* four-column-build - ms support */
.grid-wrapper-min-max.four-column {
  -ms-grid-column-span: 3;
}
.grid-wrapper-min-max.four-column .grid-item:nth-child(1) {
-ms-grid-column: 1;
-ms-grid-column-span: 3;
}
.grid-wrapper-min-max.four-column .grid-item:nth-child(2) {
-ms-grid-column: 4;
-ms-grid-column-span: 3;
}
.grid-wrapper-min-max.four-column .grid-item:nth-child(3) {
-ms-grid-column: 7;
-ms-grid-column-span: 3;
}
.grid-wrapper-min-max.four-column .grid-item:nth-child(4) {
-ms-grid-column: 10;
-ms-grid-column-span: 3;
}

/* four-column-eight-column-build */
/* eight-column-four-column-build */
/* three-column-nine-column-build */
/* nine-column-three-column-build */

.grid-wrapper-min-max.four-column-eight-column { -ms-grid-columns: (33% 1fr); }
.grid-wrapper-min-max.eight-column-four-column { -ms-grid-columns: (1fr 33%); }
.grid-wrapper-min-max.three-column-nine-column { -ms-grid-columns: (25% 1fr); }
.grid-wrapper-min-max.nine-column-three-column { -ms-grid-columns: (1fr 25%); }

/* three-column-nine-column-build */

.grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
}
.grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(2) {
  -ms-grid-column: 4;
  -ms-grid-column-span: 9;
}
.grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(3),
.grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(4) {
  display: none;
}

/* nine-column-three-column-build */

.grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 9;
}
.grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(2) {
  -ms-grid-column: 10;
  -ms-grid-column-span: 3;
}
.grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(3),
.grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(4) {
  display: none;
}

/* eight-column-four-column-build */

.grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 8;
}
.grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(2){
  -ms-grid-column: 9;
  -ms-grid-column-span: 12;
}
.grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(3),
.grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(4) {
  display: none;
}

/* four-column-eight-column-build */

.grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 4;
}
.grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(2) {
  -ms-grid-column: 5;
  -ms-grid-column-span: 12;
}
.grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(3),
.grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(4) {
  display: none;
}

/* Microsoft Edge Browser 12+ (All) - @supports method */

@supports (-ms-ime-align:auto) {
  .grid-wrapper-min-max.one-column {
    grid: 0;
  }
  .grid-wrapper-min-max.one-column,
  .grid-wrapper-min-max.two-column,
  .grid-wrapper-min-max.three-column,
  .grid-wrapper-min-max.four-column,
  .grid-wrapper-min-max.four-column-eight-column,
  .grid-wrapper-min-max.eight-column-four-column,
  .grid-wrapper-min-max.three-column-nine-column,
  .grid-wrapper-min-max.nine-column-three-column,
  #panel-cards .grid-wrapper-min-max.card-horizontal {
    .grid-item {
      padding: 1rem;
    }
  }
}

/* media queries */

/* 1400 */

/* 768 */
@media only screen and (max-width: 768px) {
/* *-column-build - all to span 12 - change display */
  .grid-wrapper-min-max.two-column .grid-item,
  .grid-wrapper-min-max.three-column .grid-item,
  .grid-wrapper-min-max.four-column .grid-item,
  .grid-wrapper-min-max.one-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.two-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.two-column .grid-item:nth-child(2),
  .grid-wrapper-min-max.three-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.three-column .grid-item:nth-child(2),
  .grid-wrapper-min-max.three-column .grid-item:nth-child(3),
  .grid-wrapper-min-max.four-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.four-column .grid-item:nth-child(2),
  .grid-wrapper-min-max.four-column .grid-item:nth-child(3),
  .grid-wrapper-min-max.four-column .grid-item:nth-child(4) {
    display: block;
    margin: 0 auto;
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
  }
/* grid-wrapper - all columns - span12 */
/* three-nine + nine-three */
  .grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(2),
  .grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(3),
  .grid-wrapper-min-max.three-column-nine-column .grid-item:nth-child(4),
  .grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(2),
  .grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(3),
  .grid-wrapper-min-max.nine-column-three-column .grid-item:nth-child(4),
/* eight-four + four-eight */
  .grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(2),
  .grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(3),
  .grid-wrapper-min-max.eight-column-four-column .grid-item:nth-child(4),
  .grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(1),
  .grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(2),
  .grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(3),
  .grid-wrapper-min-max.four-column-eight-column .grid-item:nth-child(4) {
    display: block;
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
  }
  @include phone-portrait {
    .grid-item {
      display: block;
      margin: 1rem 0;
    }
  }
}

/* image grid */

#panel-image-grid .grid-wrapper-min-max .grid-item {
  height: auto;
}

/* IE 11 ONLY */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

#panel-intro .grid-area-one {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  margin: 0 2rem 0 0;
  @include phone-portrait {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }
}
#panel-intro .grid-area-two {
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  margin: 0 0 2rem 0;
  @include phone-portrait {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
}
#panel-intro .grid-area-three {
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  @include phone-portrait {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 3;
  }
}
#panel-intro #home {
  display: block;
}

/* grid-gap */
.grid-wrapper-min-max.one-column {
  grid: 0;
}

.grid-wrapper-min-max.one-column,
.grid-wrapper-min-max.two-column,
.grid-wrapper-min-max.three-column,
.grid-wrapper-min-max.four-column,
.grid-wrapper-min-max.four-column-eight-column,
.grid-wrapper-min-max.eight-column-four-column,
.grid-wrapper-min-max.three-column-nine-column,
.grid-wrapper-min-max.nine-column-three-column,
#panel-cards .grid-wrapper-min-max.card-horizontal {
  .grid-item {
    padding: 1rem;
  }
}
/* first grid-item override */
#panel-image-lead .grid-wrapper-min-max:nth-child(1),
#panel-image-lead-resize .grid-wrapper-min-max:nth-child(1),
#panel-audio .grid-wrapper-min-max:nth-child(1),
#panel-accordion .grid-wrapper-min-max:nth-child(1),
#panel-form .grid-wrapper-min-max:nth-child(1),
#panel-pagination .grid-wrapper-min-max:nth-child(1),
#panel-tabs .grid-wrapper-min-max:nth-child(1),
#panel-image-caption .grid-wrapper-min-max:nth-child(1),
#panel-video-embed .grid-wrapper-min-max:nth-child(1),
#panel-carousel-scroll .grid-wrapper-min-max:nth-child(1),
#modaal-content-carousel .grid-wrapper-min-max:nth-child(1) {
  .grid-item {
    -ms-grid-column-span: 12 !important;
  }
}

#panel-image-lead .grid-wrapper-min-max .grid-item:nth-child(1){
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
}

/* image grid - image areas */
#panel-image-grid .grid-wrapper-min-max {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  .grid-item {
    display: -ms-flexbox;
    -ms-flex-direction: column;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(2) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(3) {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(4) {
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(5) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(6) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(7) {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(8) {
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(9) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(10) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(11) {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(12) {
  -ms-grid-column: 4;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
/* grid areas */
/* image - text */
#panel-image-text .grid-wrapper-grid-area {
  .grid-area-image {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  @include phone-portrait {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
  }
  .grid-area-content {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  @include phone-portrait {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }
  }
}
#panel-image-text .grid-wrapper-grid-area.toggle-grid-area {
  .grid-area-image {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  @include phone-portrait {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  }
  }
  .grid-area-content {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
  @include phone-portrait {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
  }
  }
}
/* image-grid */
#panel-image-grid-area .grid-wrapper-grid-area {
  display: -ms-grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr;
  .grid-area {
    display: -ms-flexbox;
    -ms-flex-direction: column;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
#panel-image-grid-area .grid-wrapper-grid-area {
  .grid-area.grid-area-one {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
  }
  .grid-area.grid-area-two {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
  }
  .grid-area.grid-area-three {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
    -ms-grid-row: 2;
  }
  .grid-area.grid-area-four {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
  }
  .grid-area.grid-area-five {
    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
    -ms-grid-row: 3;
    -ms-grid-row-span: 2;
  }
}
.calendar-card-date {
  margin: 0 0 1.125rem;
}
#panel-image-highlights .grid-wrapper-min-max.three-column {
  .grid-item {
    padding: 0;
  }
}
#panel-carousel-scroll .grid-wrapper-min-max {
  min-height: 700px;
}
#panel-carousel-scroll .grid-wrapper-min-max:nth-child(1) {
  .grid-item .item-content {
    padding: 4rem;
  }
}
#panel-image-caption .grid-wrapper-min-max :nth-child(1).grid-item {
  -ms-grid-column-span: 12 !important;
}
/* 1080 */
@media only screen and (max-width: 1080px) {
#panel-image-grid .grid-wrapper-min-max {
  -ms-grid-columns: 1fr 1fr 1fr;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(2) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(3) {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(4) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(5) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(6) {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(7) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(8) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(9) {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(10) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(11) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(12) {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
}
#panel-image-grid-area .grid-wrapper-grid-area {
  .grid-area.grid-area-one {
    -ms-grid-column: 1;
    -ms-grid-column-span: 4;
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
  }
  .grid-area.grid-area-two {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    -ms-grid-row: 3;
  }
  .grid-area.grid-area-three {
    -ms-grid-column: 3;
    -ms-grid-column-span: 2;
    -ms-grid-row: 3;
  }
  .grid-area.grid-area-four {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 4;
    -ms-grid-row-span: 2;
  }
  .grid-area.grid-area-five {
    -ms-grid-column: 2;
    -ms-grid-column-span: 3;
    -ms-grid-row: 4;
    -ms-grid-row-span: 2;
  }
}
}
/* 1080 - close */
/* 768 */
@media only screen and (max-width: 768px) {
#panel-image-grid .grid-wrapper-min-max {
  -ms-grid-columns: 1fr 1fr;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(1) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(2) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(3) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(4) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(5) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(6) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(7) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(8) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(9) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(10) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(11) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 6;
  -ms-grid-row-span: 1;
}
#panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(12) {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 6;
  -ms-grid-row-span: 1;
}
}
/* 768 - close */
/* 415 */
@media only screen and (max-width: 415px) {
  #panel-image-grid .grid-wrapper-min-max {
    display: block;
    width: 100%;
  }
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(1),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(2),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(3),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(4),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(5),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(6),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(7),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(8),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(9),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(10),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(11),
  #panel-image-grid .grid-wrapper-min-max .grid-item:nth-child(12) {
    display: block;
    width: 100%;
  }
  #panel-image-grid-area .grid-wrapper-grid-area {
    display: block;
    width: 100%;
    .grid-area.grid-area-one,
    .grid-area.grid-area-two,
    .grid-area.grid-area-three,
    .grid-area.grid-area-four,
    .grid-area.grid-area-five {
      display: block;
      width: 100%;
    }
  }
}
/* 415 - close */
}
/* close of IE specific css */
